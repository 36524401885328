import Head from "next/head";
import Script from "next/script";
import { classNames } from "../utils/classNames";
import { Sidebar } from "./sidebar";

type LayoutProps = {
  children: React.ReactNode;
  withSidebar?: boolean;
  headTitle?: string;
  noPadding?: boolean;
};

export const Layout = ({
  children,
  withSidebar,
  headTitle,
  noPadding,
}: LayoutProps) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row">
      <Head>
        <title>Palma{headTitle ? ` | ${headTitle}` : null}</title>
      </Head>
      {withSidebar ? (
        <>
          <Sidebar />
          <main className="flex-1 min-h-screen overflow-y-auto focus:outline-none">
            <div
              className={classNames(
                !noPadding && "max-w-5xl sm:px-8 2xl:px-0 pt-8 pb-16 lg:pt-16",
                "mx-auto min-h-screen flex flex-col"
              )}
            >
              {children}
            </div>
          </main>
        </>
      ) : (
        children
      )}
      <Script strategy="lazyOnload">
        {`window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "aywjk07p"
          };`}
      </Script>

      <Script strategy="lazyOnload">
        {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/aywjk07p';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
      </Script>
    </div>
  );
};
