import Image from "next/image";
import Link from "next/link";

type LogoProps = {
  size?: "sm" | "md" | "lg";
  redirectToDashboard?: boolean;
};

export const PalmaLogo = ({
  size = "lg",
  redirectToDashboard = false,
}: LogoProps) => {
  const width = size == "lg" ? 300 : size == "md" ? 225 : 150;
  return (
    <Link href={redirectToDashboard ? "/members" : "/"}>
      <span>
        <Image src="/palma-logo.svg" width={width} height={width / 2.5} />
      </span>
    </Link>
  );
};
